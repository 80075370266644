.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem 0rem 0rem;
  top: 0;
  height: 60px;
  position: fixed;
  z-index: 20;
  background: black;
  width: 100%;
  box-shadow: 0px 2px rgba(233, 233, 233);
}
.nav-menu {
  display: flex;
  padding-top: 0.8rem;
}
.nav-menu li {
  padding: 0.2rem 0.8rem;
  position: relative;
}
.nav-menu li a {
  font-size: 1rem;
  font-weight: 500;
  color: WHITE;
  text-decoration: none;
}
.nav-menu li a:hover {
  color: #fca253;
}
.nav-menu li a::after {
  content: "";
  position: absolute;
  background-color: #fca253;
  height: 2px;
  width: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}
.acctive span {
  color: #fca253;
}
.acctive div {
  content: "";
  position: absolute;
  background-color: #fca253;
  height: 2px;
  width: 60%;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}

.nav-menu li a:hover {
  color: #fca253;
}
.nav-menu li a:hover::after {
  width: 60%;
}
.icon {
  display: none;
}

@media screen and (max-width: 1050px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    top: 62px;
    left: -100%;
    z-index: 100;
    transition: 0.5s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-menu li {
    padding: 1rem 0.7rem 1rem 0;
  }
  .nav-menu li a {
    font-size: 1.4rem;
    color: black;
  }
  .icon {
    display: initial;
    transition: 0, 5s ease-in-out;
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .nav-menu li a {
    font-size: 2rem;
    color: black;
  }
}
@media screen and (max-width: 300px) {
  .nav-menu li a {
    font-size: 1rem;
  }
}
