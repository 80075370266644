#success {
  display: none;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}
#success p {
  color: hsl(120, 90%, 44%);
  font-weight: 500;
}
#danger {
  display: none;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}
#danger p {
  color: red;
  font-weight: 500;
}
.contact-img {
  text-align: center;
  padding-top: 20px;
}
@media screen and (max-width: 1000px) {
  .contact-img img {
    width: 80%;
  }
}
