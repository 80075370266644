.contact {
  margin-top: 0px;
  padding-top: 100px;
  text-align: center;
  position: relative;
  background-image: url("../../images/useful_images_svg/_3c13487b-ba3d-47c4-b0ac-8f9cf1ff75e8.jpeg");
  background-repeat: no-repeat;
  background-position-y: 30%;
  background-size: 100%;
  background-position: center;
  height: 350px;
}
.contact-body-1 {
  padding-bottom: 20px;
}
.contact-head {
  width: 100%;
  color: white;
  padding: 10px 30px;
  margin-bottom: 100px;
}
.contact-head h4 {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 100px;
}
.pur p {
  margin-left: -20px;
}
.contact-head p {
  font-size: 1.5rem;
  padding-top: 30px;
  margin-bottom: 100px;
}
.contact-form {
  position: absolute;
  top: 80%;
  right: 10%;
  left: 10%;
  box-shadow: 0 0 0 2px rgba(233, 233, 233, 0.6);
  border-radius: 2px;
}
.contact-form .row {
  text-align: left;
}
.contact-form form {
  background: white;
  padding: 40px 40px;
}
.contact-form p {
  color: #05386b;
  font-size: 1rem;
  font-weight: 500;
}
.contact-form label {
  color: black;
  font-size: 1rem;
  font-weight: 400;
}
.form-check {
  padding: 5px 0px;
}
input[type="radio"] {
  box-shadow: 0 0 0 2px gray;
}
textarea {
  resize: none;
}
.contact-body {
  height: 950px;
}
#contact-success {
  padding-top: 20px;
  display: none;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}

#contact-success p {
  color: hsl(120, 90%, 44%);
  font-weight: 500;
  font-size: 1rem;
}

#contact-danger {
  padding-top: 20px;
  display: none;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}
#contact-danger p {
  color: red;
  font-weight: 500;
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .Contact-col-2 {
    padding-top: 20px;
  }
  .contact-body {
    height: 1250px;
  }
  .form-check {
    margin-left: 30px;
  }
  .contact-form label {
    font-size: 1rem;
  }
  .pur p {
    margin-left: 0px;
  }
}

@media screen and (max-width: 300px) {
  .contact-head p {
    font-size: 0.9rem;
  }
  .contact-head h4 {
    font-size: 1.8rem;
  }
  .contact-form p {
    font-size: 0.9rem;
  }
  .contact-body {
    height: 1250px;
  }
  #contact-danger p {
    font-size: 0.9rem;
  }
  #contact-success p {
    font-size: 0.9rem;
  }
  .contact-form label {
    font-size: 0.7rem;
  }
  .contact {
    height: 200px;
  }
}
@media screen and (min-width: 768px) and (max-width: 830px) {
  .contact-body {
    height: 1050px;
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .contact {
    padding-top: 150px;
  }
}
