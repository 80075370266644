.swo {
  margin-top: 60px;
  padding: 10px 30px 0px 30px;
}
.swo-header {
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  color: #05386b;
  text-align: left;
  margin-bottom: 20px;
  height: 300px;
  background-image: url("../../images/useful_images_svg/_6e30bc9f-dd93-4710-b000-92d8ac6aeb9c.jpeg");
}
.swo .head {
  color: #05386b;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  /* padding-top: 20px;
  margin-top: 50px;
  margin-bottom: 0px; */
}
.swo img {
  max-width: 80%;
  max-height: 300px;
}
.swo p {
  font-weight: 500;
  font-size: 1rem;
}

.swo h4 {
  color: #fca253;
  margin-top: 15px;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 1.3rem;
}
.top h4 {
  margin-top: 15px;
}
.or {
  color: #05386b;
}

.mid {
  margin-top: 50px;
  text-align: center;
}
.down {
  margin-top: 20px;
  text-align: center;
}

.types h4 {
  margin-top: 30px;
}
@media screen and (max-width: 300px) {
  .swo-header {
    height: 200px;
  }
}

@media screen and (max-width: 541px) {
  .mid {
    margin-bottom: 50px;
  }
  .head {
    font-size: 1.8rem;
  }
}
