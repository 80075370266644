.percentage {
  height: 200px;
  align-items: center;
  padding-top: 50px;
}
.percentage h4 {
  color: #fca253;
  font-weight: 500;
  font-size: 1.5rem;
}
.percentage .count-up {
  color: #05386b;
  font-weight: 600;
  font-size: larger;
}
