.about {
  margin-top: 60px;
  padding: 10px 30px;
  margin-bottom: 50px;
}
.about-header {
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-position-y: 40%;
  color: white;
  text-align: left;
  margin-bottom: 20px;
  height: 300px;
  background-image: url("../../images/useful_images_svg/_1f738d6d-0235-4de8-84c1-5dac8cd3e610.jpeg");
}

.about h4 {
  text-align: center;
  color: #d14836;
}
.content h4 {
  color: white;
  font-weight: 700;
}
.about-contact h4 {
  font-weight: 600;
}
.stHead {
  margin-bottom: 30px;
}
.about-contact .ndHead {
  margin-bottom: 60px;
  color: #05386b;
  font-weight: 500;
}
.about-contact {
  margin-top: 100px;
  text-align: center;
}
.about-contact a {
  text-decoration: none;
}

.button-div {
  margin-top: 30px;
}
.team {
  margin-top: 20px;
}
.team img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
}

.about .about-prof {
  font-size: 1rem;
  color: #05386b;
}
.about p {
  font-weight: 500;
  font-size: 1rem;
}
/* .team p {
  padding-top: 5px;
} */
.intern {
  margin-top: 100px;
}
.intern .about-name {
  padding-top: 20px;
  font-size: 1.1rem;
}
.core .about-name {
  padding-top: 20px;
  font-size: 1.1rem;
}
/* .core,
.intern {
  
} */
/* .space-about {
  width: 10px;
} */
.core-in {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0px 0px 15px -2px #444444;
  height: 350px;
  position: relative;
}
.linkedin {
  text-align: left;
  bottom: 10px;
  position: absolute;
}

.intern img {
  height: 200px;
  width: 200px;
}
@media screen and (max-width: 950px) {
  .intern {
    margin-top: 0px;
  }
}
@media screen and (max-width: 1024px) {
  .core-in {
    margin: 10px !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .team img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }
  .intern img {
    height: 180px;
    width: 180px;
  }
}
@media screen and (max-width: 300px) {
  .team img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }
  .intern img {
    height: 150px;
    width: 150px;
  }
  .core-in {
    height: 380px;
  }
  .intern p,
  .core p {
    font-size: 1rem;
  }
  .core-in .about-name {
    font-size: 1.1rem;
  }
  .about-header {
    height: 200px;
  }
}
