.Body {
  margin-top: 0px;
  padding: 10px 30px;
}
.Body h4 {
  color: #05386b;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}
.text-top {
  margin-bottom: 0px;
}
.text {
  margin-bottom: 30px;
}
.Main {
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}
.img-wrapper {
  width: 100%;
  height: 200px;
  background-image: url(../../images/useful_images_svg/image8.svg);
  background-repeat: no-repeat;
  padding: 0;
  padding-bottom: 92%;
}
.Col-1,
.Col-2 {
  background: rgba(255, 255, 255, 0.7);
  padding: 50px;
  box-shadow: 0px 0px 15px -2px #444444;
  /* border: 1px solid #05386b;
  border-radius: 20px; */
  height: 680px;
}
.Col-2 p {
  color: black;
  font-weight: 450;
  padding-top: 50px;
  padding-bottom: 50px;
}
.Col-1 p {
  color: black;
  font-weight: 450;
  padding-top: 50px;
  padding-bottom: 50px;
}
.l-250 {
  display: none;
}
.container-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ret {
  width: 50%;
  float: left;
}
.text-1 {
  width: 50%;
  float: left;
}
.ret img {
  max-width: 500px;
  max-height: 200px;
}
.text-1 {
  display: flex;
  flex-direction: column;
}
.text-1 h4 {
  float: left;
  font-size: 1.2rem;
}
.inner p {
  display: inline-block;
  text-align: left;
  color: black;
  font-size: 0.9rem;
  font-weight: 500;
}

/* .row-body {
  margin-bottom: 50px;
}
.row-body p {
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
}
.res-grid {
  padding: 20px 20px;
  position: relative;
  overflow: hidden;
}
.sol {
  width: 400px;
  height: 400px;
}
.text-image {
  height: 100%;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
.text-image img {
  object-fit: cover;
  object-position: left;
}
.text-image:hover img {
  transform: scale(1.1);
  filter: blur(3px);
}
.text-image:hover .blur {
  left: 0%;
}
.t-img {
  position: relative;
  height: 100%;
  width: 100%;
  top: 50%;
}
.t-img h3 {
  color: #05386b;
  font-weight: 800;
}
.blur:hover {
  left: 0%;
}
.blur {
  position: absolute;
  top: 0;
  background-color: rgba(233, 233, 233, 0.7);
  height: 100%;
  width: 100%;
  left: -200%;
  z-index: 5;
  transition: 0.5s ease-in-out;
}
.contact img {
  height: 100%;
  width: 100%;
}

.center {
  margin-left: auto;
  margin-right: auto;
}
tr,
td {
  text-align: center;
} */
.button {
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
  padding: 10px 20px;
  background-color: #fca253;
  border-radius: 40px;
  border: 1px solid #fca253;
  font-weight: 600;
}
.button:hover {
  color: #fca253;
  background: transparent;
}
.form {
  width: 100%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 30px;
  text-align: center;
}

.form form {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.form h4 {
  padding-top: 70px;
  color: #d14836;
  font-weight: 600;
}

.form-text {
  background-color: whitesmoke;
  border: 2px solid rgba(220, 220, 220, 0.7);
  border-radius: 5px;
}

.form p {
  color: black;
  font-weight: 600;
}

.awards p {
  color: black;
  font-weight: 500;
  font-size: 1.05rem;
  padding: 5px;
}
#right {
  float: right;
}
#left {
  float: left;
}
.center_img {
  padding-top: 10px;
}
.cour_item {
  text-align: center;
}
.htw_home {
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 0px;
  margin-top: 0;
}
.htw_home h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 840px) {
  .a-1 img {
    height: 150px;
    width: 150px;
  }
  .a-1 {
    padding: 30px 20px;
  }

  .Col-1 {
    margin-bottom: 30px;
  }
  .Main {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .inner h4 {
    font-size: 1rem;
  }
  #left {
    float: none;
  }
  .left {
    display: none;
  }
  #right {
    float: none;
  }
  .Col-1 img {
    height: 50%;
  }
  .Col-2 img {
    height: 50%;
  }
  .awards p {
    padding: 20px;
  }
  .cour_item img {
    width: 120px;
    height: 120px;
  }
}
@media screen and (min-width: 500px) and (max-width: 550px) {
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
  }
  .react-multiple-carousel__arrow--left {
    left: 0 !important;
  }
  .cour_item img {
    width: 120px;
    height: 120px;
  }
  .awards p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 300px) {
  .container-1 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-right: 25px;
  }
  .l-250 {
    display: inline-block;
  }
  .l-250 p {
    padding-top: 20px;
    text-align: center;
    color: black;
    font-size: 0.9rem;
    font-weight: 450;
  }
  .inner-p {
    display: none;
  }
  .inner h4 {
    float: left;
  }
  .Body .text {
    font-size: 1.5rem;
    padding-bottom: 0px;
  }
  .Col-1 img {
    height: 40%;
  }
  .Col-2 img {
    height: 40%;
  }
  .react-multiple-carousel__arrow {
    min-height: 30px !important;
    min-width: 30px !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
  }
  .react-multiple-carousel__arrow--left {
    left: 0 !important;
  }

  .cour_item img {
    width: 110px;
    height: 110px;
  }
  .awards p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .ret img {
    height: 150px;
    width: 150px;
  }
}
