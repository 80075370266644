.slider {
  margin-top: 60px;
}
.slider img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.text-img {
  background-color: rgba(0, 0, 0, 0.4);
}
.text-img h3 {
  color: white;
}
.slider h4 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 2px;
  color: #05386b;
}

@media screen and (min-width: 1000px) {
  .slider img {
    height: 60vh;
    padding: 10px 20px;
  }
  .pos-top img {
    object-position: 0px -70px;
  }
}
@media screen and (max-width: 1000px) {
  .slider img {
    height: 40vh;
  }
}
/* @media screen and (min-width: 800px) and (max-width: 1000px) {
  .slider {
    margin-top: 150px;
  }
} */
@media screen and (max-width: 300px) {
  .slider h4 {
    font-size: 1.5rem;
  }
}
