.row-f {
  text-align: left;
}
.row-c {
  text-align: center;
}
.footer p {
  color: white;
  font-size: 1rem;
}
.row-head p {
  color: #fca253;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}
.row-head p::before {
  content: "";
  position: absolute;
  width: 10%;
  height: 1px;
  bottom: 0;
  left: 5%;
  border-bottom: 1px solid #fca253;
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.make-left {
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .empty {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .footer p {
    font-size: 0.8rem;
  }
}
.row-f a {
  text-decoration: none;
  width: 85%;
}
.main_row {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30px;
}
.footer {
  background: radial-gradient(rgb(19, 30, 37) 24.5%, rgb(19, 30, 37) 66%);
  padding: 30px;
  border-top-left-radius: 100px;
}
.logo {
  margin-bottom: 10px;
}
.copy {
  width: 100%;
  padding: 0;
  margin: 0;
}

.copy p {
  font-size: 0.7rem;
  color: white;
}
.illu p {
  font-size: smaller;
  color: rgba(120, 120, 120);
}
.illu a {
  color: rgba(120, 120, 120);
}
