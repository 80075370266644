.htw {
  margin-top: 60px;
  padding: 10px 30px;
  margin-bottom: 50px;
}
.htw h4 {
  font-size: 1.3rem;
}
.htw-header {
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 60%;
  background-size: 100%;
  color: white;
  text-align: left;
  margin-bottom: 20px;
  height: 300px;
  background-image: url("../../images/useful_images_svg/_23b45845-d1bb-4dbd-8c05-7075355fe30b.jpeg");
}
.htw_main img {
  max-width: 80%;
  max-height: 400px;
}
.htw p {
  font-weight: 500;
  font-size: 1rem;
}
.or {
  color: #05386b;
}
.htw_main img {
  margin-bottom: 20px;
}
.htw_main h4 {
  color: #fca253;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 500;
}
.down-htw,
.mid_htw {
  text-align: center;
  margin-top: 40px;
  width: 70%;
}
/* @media screen and (min-width: 800px) and (max-width: 1000px) {
  .htw {
    margin-top: 150px;
  }
} */
