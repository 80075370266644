* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "poppins", sans-serif;
}
body {
  background: white;
}
h1,
h4,
h3,
a {
  color: black;
}
ul {
  list-style: none;
}
.row {
  text-align: center;
}
.hr {
  width: 10%;
  height: 5px;
  color: black;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid black;
}
.space-60 {
  height: 60px;
}
.space-30 {
  height: 30px;
}
.react-multiple-carousel__arrow {
  z-index: 10 !important;
}
